import {
    Marker as GoogleMarker,
    InfoWindow,
} from '@react-google-maps/api';

import { useState } from 'react';

import carMarker from '../assets/marker/car-marker.png';
import hintMarker from '../assets/marker/hint-marker.png';
import huntMarker from '../assets/marker/hunt-marker.png';
import messageMarker from '../assets/marker/message-marker.png';
import groupMarker from '../assets/marker/group-marker.png';

interface Props {
    position: { latitude: number, longitude: number },
    type: 'car' | 'group' | 'hint' | 'hunt' | 'message',
    draggable?: boolean,
    onDragEnd: (latitude: number | undefined, longitude: number | undefined) => void,
    setSidebar?: any,
    infoWindowOpen?: boolean,
    children?: any,
}

export const Marker = (props: Props) => {
    const [infoWindowOpen, setInfoWindowOpen] = useState(false);

    if (props.infoWindowOpen) {
        setInfoWindowOpen(true);
    }

    const handleToggleOpen = () => {
        setInfoWindowOpen(!infoWindowOpen);
    };

    let icon = undefined;

    switch (props.type) {
        case 'car':
            icon = carMarker;
            break;

        case 'group':
            icon = groupMarker;
            break;

        case 'hint':
            icon = hintMarker;
            break;

        case 'hunt':
            icon = huntMarker;
            break;

        case 'message':
            icon = messageMarker;
            break;

        default:
            icon = undefined
            break;
    }

    return <GoogleMarker
        position={{ lat: props.position.latitude, lng: props.position.longitude }}
        draggable={props.draggable || false}
        onDragEnd={(e) => props.onDragEnd(e.latLng?.lat(), e.latLng?.lng())}
        onClick={() => {
            if (props.setSidebar) {
                return props.setSidebar(props.children);
            }
        }}
        icon={icon}
    >
        {infoWindowOpen && (
            <InfoWindow onCloseClick={handleToggleOpen}>
                <div>
                    {props.children}
                </div>
            </InfoWindow>
        )}
    </GoogleMarker >
}