import {
    useGetList,
} from "react-admin";
import { Car } from "../generated";
import { Marker } from "./Marker";
import { Typography, CardContent } from "@mui/material";
import DateTimeField from "./DateTimeField";
import { getHourFilter } from "../utils/dates";
interface Props {
    setSidebar: any,
}

export const Cars = (props: Props) => {
    const filter: any = getHourFilter();

    const cars = useGetList(
        'cars',
        {
            pagination: { page: 1, perPage: 100 },
            filter: filter,
        }
    ).data;
    return (<>
        {cars && cars.map((car: Car, index: number) => (
            <Marker
                position={{ latitude: car.latitude, longitude: car.longitude }}
                type="car"
                key={"car-" + index}
                setSidebar={props.setSidebar}
            >
                <CardContent>
                    <Typography variant="h5" component="div">
                        {car.name}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        <DateTimeField source="created" record={car} />
                    </Typography>
                </CardContent>
            </Marker>
        ))}
    </>)

}
